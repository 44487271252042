import React from 'react';
import {InputText} from 'primereact/inputtext';
import {AppContext, MessageService, TwoDialog, TwoToast} from 'two-app-ui';
import {Address, Company, Location, QueryParameter, Run} from 'two-core';
import {Toast} from 'primereact/toast';
import {messages} from '../../config/messages';
import LocationsService from '../../services/LocationsService';
import {Dropdown} from 'primereact/dropdown';
import {auStateOptions, locationTypeOptions} from '../../utils/LocationUtil';
import {InputTextarea} from 'primereact/inputtextarea';
import {InputSwitch} from 'primereact/inputswitch';
import {Divider} from 'primereact/divider';
import CompaniesService from '../../services/CompaniesService';
import config from '../../config/config';

interface Props {
  showDialog: boolean;
  onHide: () => void;
  toast: React.RefObject<Toast>;
  location?: Location;
}

interface State {
  saving: boolean;
  locationPatch: Partial<Location>;
  loadingDealerships?: boolean;
  selectedDealership?: Company;
  dealerships?: Company[];
  dealershipsFilter?: string;
  offset: number;
  pageSize: number;
}

export class EditLocationDialog extends React.Component<Props, State> {
  static contextType = AppContext;

  locationsService?: LocationsService;
  companiesService?: CompaniesService;
  twoToast?: TwoToast;
  typingTimer?: NodeJS.Timeout;

  constructor(props: Props) {
    super(props);
    this.state = {
      saving: false,
      locationPatch: {},
      offset: 0,
      pageSize: 50,
    };

    this.onSave = this.onSave.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onShow = this.onShow.bind(this);
    this.onLocationChange = this.onLocationChange.bind(this);
    this.onAddressChange = this.onAddressChange.bind(this);
    this.onDealershipChange = this.onDealershipChange.bind(this);
    this.loadDealerships = this.loadDealerships.bind(this);
  }

  componentDidMount() {
    this.locationsService = this.context.locationsService;
    this.companiesService = this.context.companiesService;
    this.twoToast = this.context.twoToast;
  }

  async loadDealerships(offset: number, pageSize: number, id?: string, searchText?: string) {
    this.setState(() => ({loadingDealerships: true}));
    const filters = [];
    if (id) {
      filters.push(JSON.stringify({field: 'id', value: id}));
    }
    if (searchText) {
      filters.push(
        JSON.stringify({
          orConditions: [
            {field: 'name', value: searchText, condition: 'iLike'},
            {field: 'trading_as', value: searchText, condition: 'iLike'},
            {field: 'account_number', value: searchText, condition: 'iLike'},
          ],
        })
      );
    }

    const params: QueryParameter = {offset, page_size: pageSize, filters};
    return this.companiesService
      ?.getCompanies(params)
      .then(data => {
        return (data?.records as Company[]) ?? [];
      })
      .catch(error => {
        this.twoToast?.showError('Failed loading dealerships, please refresh and try again.');
        console.error(error);
        return undefined;
      })
      .finally(() => this.setState(() => ({loadingDealerships: false})));
  }

  async createLocation(location: Partial<Location>) {
    this.setState({saving: true});

    return this.locationsService
      ?.createLocation(location)
      .then(() => {
        this.twoToast?.showSuccess('Location created successfully.');

        this.onHide();
        MessageService.sendMessage(messages.locationUpdate);
      })
      .catch(error => {
        this.twoToast?.showError('Sorry, Location create failed, please try again.');
        console.error('error: ' + error);
        this.setState({saving: false});
      });
  }

  async updateLocation(id: number, location: Partial<Location>) {
    this.setState({saving: true});

    return this.locationsService
      ?.updateLocation(id, location)
      .then(() => {
        this.twoToast?.showSuccess('Location updated successfully.');

        this.onHide();
        MessageService.sendMessage(messages.locationUpdate);
      })
      .catch(error => {
        this.twoToast?.showError('Sorry, Location update failed, please try again.');
        console.error('error: ' + error);
        this.setState({saving: false});
      });
  }

  async onShow() {
    const {location} = this.props;
    if (location?.dealership_id) {
      const dealerships = await this.loadDealerships(0, 50, location.dealership_id);
      const selectedDealership = dealerships?.[0];
      this.setState({dealerships, selectedDealership, offset: 0, pageSize: 50});
    } else {
      const dealerships = await this.loadDealerships(0, 50);
      this.setState({dealerships, offset: 0, pageSize: 50});
    }
  }
  onHide() {
    this.setState({
      locationPatch: {},
      saving: false,
      selectedDealership: undefined,
      loadingDealerships: false,
      dealerships: undefined,
      offset: 0,
      pageSize: 50,
    });
    this.props.onHide();
  }

  validate(location: Partial<Location>) {
    const messages = [];
    if (!location.name) {
      messages.push('Name is required');
    }
    if (!location.type) {
      messages.push('Type is required');
    }
    if (!location.address?.state) {
      messages.push('State is required');
    }
    if (!location.address?.street) {
      messages.push('Street is required');
    }
    if (!location.address?.suburb) {
      messages.push('Suburb is required');
    }
    if (!location.address?.country) {
      messages.push('Country is required');
    }
    if (!location.address?.postCode) {
      messages.push('Post code is required');
    }
    if (location.type === 'dealership' && !location.dealership_id) {
      messages.push('Dealership is required');
    }
    if (messages.length > 0) {
      this.twoToast?.showError(
        <ul>
          {messages.map((m, i) => (
            <li key={i}>{m}</li>
          ))}
        </ul>
      );
      return false;
    }
    return true;
  }

  async onSave() {
    const {location} = this.props;
    const {locationPatch} = this.state;
    if (this.validate({...location, ...locationPatch})) {
      if (location?.id) {
        await this.updateLocation(location.id, {...locationPatch});
      } else {
        await this.createLocation({...locationPatch});
      }
    }
  }

  onLocationChange(pLocationPatch: Partial<Location>) {
    this.setState((state: State) => ({locationPatch: {...state.locationPatch, ...pLocationPatch}}));
  }

  onAddressChange(pAddressPatch: Partial<Address>) {
    const {location} = this.props;
    const {locationPatch} = this.state;
    this.onLocationChange({address: {...location?.address, ...locationPatch.address, ...pAddressPatch}});
  }

  onDealershipChange(dealership: Company) {
    this.setState({selectedDealership: dealership});
    this.onLocationChange({dealership_id: dealership.id});
  }

  async onDealershipFilterChange(value: string) {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(async () => {
      const dealerships = await this.loadDealerships(0, 50, undefined, value);
      this.setState({
        dealershipsFilter: value,
        offset: 0,
        dealerships,
        selectedDealership: undefined,
      });
    }, config().system.stopTypingDetection);
  }

  async onDealershipLazyLoad(lastVisibleItemIndex: number) {
    const {dealershipsFilter, offset, pageSize, dealerships} = this.state;
    if (lastVisibleItemIndex >= offset + pageSize) {
      console.debug(lastVisibleItemIndex, offset + pageSize, dealershipsFilter);
      const loadedDealerships = await this.loadDealerships(offset + pageSize, pageSize, undefined, dealershipsFilter);

      this.setState({
        dealerships: [...(dealerships ?? []), ...(loadedDealerships ?? [])],
        offset: offset + pageSize,
      });
    }
  }

  dealershipOptionTemplate(dealership: Company) {
    if (dealership) {
      let title = dealership?.account_number;
      if (dealership.trading_as) {
        title += ` (${dealership.trading_as})`;
      } else if (dealership.name) {
        title += ` (${dealership.name})`;
      }
      return <div>{title}</div>;
    }
    return 'empty';
  }

  renderContent(
    location: Partial<Location>,
    selectedDealership?: Company,
    dealerships?: Company[],
    loadingDealerships?: boolean
  ) {
    return (
      <div id="edit_location_dialog_content" className="p-fluid w-100">
        <div className="p-field p-grid">
          <label className="p-col-2">name</label>
          <div className="p-col-4">
            <InputText value={location.name} onChange={e => this.onLocationChange({name: e.target.value})} />
          </div>
          <label className="p-col-2">type</label>
          <div className="p-col-4">
            <Dropdown
              value={location.type}
              options={locationTypeOptions}
              onChange={e => this.onLocationChange({type: e.value})}
            />
          </div>
        </div>
        <div className="p-field p-grid">
          <label className="p-col-2">state</label>
          <div className="p-col-4">
            <Dropdown
              value={location?.state_id}
              options={auStateOptions}
              onChange={e => this.onLocationChange({state_id: e.value})}
            />
          </div>
          {location.type === 'dealership' && (
            <>
              <label className="p-col-2">dealership</label>
              <div className="p-col-4">
                <Dropdown
                  value={selectedDealership}
                  options={dealerships ?? []}
                  onChange={e => this.onDealershipChange(e.value as Company)}
                  filter
                  itemTemplate={dealership => this.dealershipOptionTemplate(dealership)}
                  valueTemplate={dealership => this.dealershipOptionTemplate(dealership)}
                  optionLabel={'name'}
                  virtualScrollerOptions={{
                    lazy: true,
                    onLazyLoad: e => this.onDealershipLazyLoad(e.last as number),
                    showLoader: true,
                    loading: loadingDealerships,
                    itemSize: 45,
                  }}
                  onFilter={e => this.onDealershipFilterChange(e.filter)}
                />
              </div>
            </>
          )}
        </div>
        <Divider />
        <div className="p-field p-grid">
          <label className="p-col-2">street</label>
          <div className="p-col-4">
            <InputText
              value={location.address?.street}
              onChange={e => this.onAddressChange({street: e.target.value})}
            />
          </div>
          <label className="p-col-2">suburb</label>
          <div className="p-col-4">
            <InputText
              value={location.address?.suburb}
              onChange={e => this.onAddressChange({suburb: e.target.value})}
            />
          </div>
        </div>
        <div className="p-field p-grid">
          <label className="p-col-2">state</label>
          <div className="p-col-2">
            <InputText value={location.address?.state} onChange={e => this.onAddressChange({state: e.target.value})} />
          </div>
          <label className="p-col-2">country</label>
          <div className="p-col-2">
            <InputText
              value={location.address?.country}
              onChange={e => this.onAddressChange({country: e.target.value})}
            />
          </div>
          <label className="p-col-2">postcode</label>
          <div className="p-col-2">
            <InputText
              value={location.address?.postCode}
              onChange={e => this.onAddressChange({postCode: e.target.value})}
            />
          </div>
        </div>
        <Divider />
        <div className="p-field p-grid">
          <label className="p-col-2">phone number</label>
          <div className="p-col">
            <InputText
              value={location.address?.phoneNumber}
              onChange={e => this.onAddressChange({phoneNumber: e.target.value})}
            />
          </div>
        </div>
        <div className="p-field p-grid">
          <label className="p-col-2">instruction</label>
          <div className="p-col">
            <InputTextarea
              value={location.instructions}
              onChange={e => this.onLocationChange({instructions: e.target.value})}
              className="w-100"
              rows={5}
            />
          </div>
        </div>
        <div className="p-field p-grid">
          <label className="p-col-2">fitting</label>
          <div className="p-col-1">
            <InputSwitch
              checked={location.used_for_fitting}
              onChange={e => {
                this.onLocationChange({used_for_fitting: e.target.value});
              }}
            />
          </div>
          <label className="p-col-2">pick up</label>
          <div className="p-col-1">
            <InputSwitch
              checked={location.used_for_pickup}
              onChange={e => {
                this.onLocationChange({used_for_pickup: e.target.value});
              }}
            />
          </div>
          <label className="p-col-2">storage</label>
          <div className="p-col-1">
            <InputSwitch
              checked={location.used_for_storage}
              onChange={e => {
                this.onLocationChange({used_for_storage: e.target.value});
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {location} = this.props;
    const {locationPatch, selectedDealership, dealerships, loadingDealerships} = this.state;

    return (
      <TwoDialog
        header={location?.id ? 'Edit Location' : 'Add Location'}
        showDialog={this.props.showDialog}
        onShow={this.onShow}
        onHide={this.onHide}
        onSave={this.onSave}
        loading={this.state.saving}
        style={{width: '75vw'}}
        breakpoints={{'768px': '80vw', '576px': '90vw'}}
      >
        {this.renderContent({...location, ...locationPatch}, selectedDealership, dealerships, loadingDealerships)}
      </TwoDialog>
    );
  }
}
