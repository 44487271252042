import React from 'react';
import {AppContext, MessageService, TwoDataTable, TwoDialog, TwoToast} from 'two-app-ui';
import {FreightOrder, FreightOrderPatch, MapOf, QueryParameter, TleContentStageTransition} from 'two-core';
import {Button} from 'primereact/button';
import LocationsService from '../../services/LocationsService';
import {Dropdown} from 'primereact/dropdown';
import {Column} from 'primereact/column';
import {DropdownChangeParams} from 'primereact/dropdown';
import TasksService from '../../services/TasksService';
import FreightOrdersService from '../../services/FreightOrdersService';
import {FreightOrderRoute} from 'two-core/build/cjs/src/freight-order';
import {messages} from '../../config/messages';
import {RemoveDeliveredTasksDialog} from './RemoveDeliveredTasksDialog';
import {getCurrentUserId} from '../../utils/UserUtil';

export type DeliverUndeliverDialogType = 'deliver' | 'undeliver';
export type DeliverUndeliverActionScope = 'current' | 'all';
type TextKey = 'title' | 'topText' | 'note';

interface Props {
  showDialog: boolean;
  type: DeliverUndeliverDialogType;
  onHide: () => void;
  onContinue: () => void;
  orders: FreightOrder[];
}

interface State {
  loading: boolean;
  showRemoveDeliveredTasksDialog?: boolean;
  orderAggregates: FreightOrder[];
  selectedScope: DeliverUndeliverActionScope;
}

interface ActionOption {
  key: string;
  value: DeliverUndeliverActionScope;
  label: string;
}

const initialState: State = {
  loading: false,
  orderAggregates: [],
  selectedScope: 'current',
  showRemoveDeliveredTasksDialog: false,
};

class DeliverUndeliverDialog extends React.Component<Props, State> {
  static contextType = AppContext;
  locationsService: LocationsService | null = null;
  tasksService: TasksService | null = null;
  freightOrdersService: FreightOrdersService | null = null;
  twoToast?: TwoToast;

  canContinue = false;
  isLastState = false;
  scopeOptions: ActionOption[] = [];
  errorOrders: FreightOrder[] = [];

  constructor(props: Props) {
    super(props);

    this.state = {...initialState};

    this.getText = this.getText.bind(this);
    this.locationBodyTemplate = this.locationBodyTemplate.bind(this);
    this.stageBodyTemplate = this.stageBodyTemplate.bind(this);
    this.actionTypeChanged = this.actionTypeChanged.bind(this);
    this.onContinue = this.onContinue.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onRemoveFromRun = this.onRemoveFromRun.bind(this);
    this.onLeaveOnRun = this.onLeaveOnRun.bind(this);
  }

  async componentDidMount() {
    this.locationsService = this.context.locationsService;
    this.tasksService = this.context.tasksService;
    this.freightOrdersService = this.context.freightOrdersService;
    this.twoToast = this.context.twoToast;
  }

  /**
   * If there are orders in the props and the aggregates are different to the prop orders (length should suffice,
   * as the aggregates gets emptied on close) the method makes sure that aggregated orders are present for the table.
   * @param prevProps
   */
  componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.orders.length === 0 && this.state.orderAggregates.length > 0) {
      this.setState({orderAggregates: []});
    } else if (this.props.orders.length > 0 && !this.sameOrders(this.props.orders, prevProps.orders)) {
      this.assureOrderAggregates();
    }
  }

  /**
   * Compares if the lists are the same FreightOrder by id
   * @param l1
   * @param l2
   */
  sameOrders(l1: FreightOrder[], l2: FreightOrder[]): boolean {
    if (l1.length !== l2.length) {
      return false;
    }
    return (
      l1.filter(order1 => {
        return !l2.find(order2 => {
          return order1.id === order2.id;
        });
      }).length === 0
    );
  }

  /**
   * This dialog needs to be working with aggregated FreightOrder(s) and this methods makes sure that is what is
   * in the state.orders list.
   */
  async assureOrderAggregates(): Promise<void> {
    if (this.props.orders.every(order => order.order)) {
      this.setState({orderAggregates: this.props.orders});
    } else {
      const orderIds: string[] = this.props.orders.map(order => order.id!);
      this.freightOrdersService
        ?.getFreightOrders({
          filters: [
            JSON.stringify({
              field: 'id',
              condition: 'in',
              value: [orderIds],
            }),
          ],
          aggregate: true,
        } as QueryParameter)
        .then(data => {
          this.setState({orderAggregates: data.records as FreightOrder[]});
        })
        .catch(() => {
          console.error('failed to read aggregates');
        });
    }
  }

  getText(key: TextKey): string {
    const singleOrder = this.state.orderAggregates.length === 1;

    switch (key) {
      case 'title':
        return `${this.props.type === 'deliver' ? 'Deliver' : 'Un-Deliver'} Order${singleOrder ? '' : 's'}`;
      case 'topText':
        if (this.props.type === 'deliver') {
          if (!this.canContinue) {
            return `Sorry, but the order${
              singleOrder ? ' below has' : 's below have'
            } not been delivered in previous state(s), hence cannot be delivered in yours or any subsequent ones.`;
          }
          return this.isLastState
            ? `You are about to deliver the below order${singleOrder ? ' to its' : 's to their'} final destination${
                singleOrder ? '' : 's'
              }.`
            : `You are about to deliver the below order${singleOrder ? '' : 's'} to`;
        }
        if (this.props.type === 'undeliver') {
          return this.canContinue
            ? `You are about to un-deliver the below order${singleOrder ? '' : 's'}.`
            : `Sorry, but the order${
                singleOrder ? ' below has' : 's below have'
              } already been actioned in subsequent state(s), which makes them impossible to un-deliver in yours.`;
        }
        break;
      case 'note':
        if (this.props.type === 'deliver') {
          if (!this.canContinue) {
            return 'In order to deliver orders in your state they need to be deliver in all previous states.';
          }
          if (this.isLastState) {
            return `The order${
              singleOrder ? '' : 's'
            } above will be marked as delivered and placed into the final destination.`;
          }
          return this.state.selectedScope === 'all'
            ? `The order${
                singleOrder ? '' : 's'
              } above will be marked as delivered in all states and placed into the final destination.`
            : `The order${
                singleOrder ? '' : 's'
              } above will be marked as delivered to the handover location of your state, ready to be picked up by the next state's crew.`;
        }
        if (this.props.type === 'undeliver') {
          return this.canContinue
            ? `The order${singleOrder ? '' : 's'} above will be marked as un-delivered in your state only. ${
                singleOrder ? 'It' : 'they'
              } will stay in their current location, so if they are physically somewhere else, you will have to edit their current location.`
            : "In order to un-deliver orders in your state, they need to be not actioned = in stage 'Inbound' in all the subsequent states, or 'At Warehouse' in the handover location between your state and the next one.";
        }
        break;
      default:
        return 'Uknown Key';
    }
    return 'Err';
  }

  /**
   * Depending on the dialog type the method runs through all orders and checks either
   * forwards for all stages to be 'Inbound' or backwards for all to be 'Delivered'.
   */
  initialise() {
    let canContinue = true;
    let isLastState = true;
    const failedOrders: FreightOrder[] = [];

    const currentState = localStorage.getItem('current state')!;
    for (const order of this.state.orderAggregates) {
      const currentStateHandoverLocationId = order.route![currentState].handover_location_id;
      const currentStateRouteIndex = order.route![currentState].index;
      for (const routePart of Object.values(order.route!)) {
        if (this.props.type === 'deliver') {
          if (routePart.index > currentStateRouteIndex) {
            isLastState = false;
          } else if (routePart.index < currentStateRouteIndex && routePart.stage !== 'Delivered') {
            canContinue = false;
            failedOrders.push(order);
          }
        } else if (this.props.type === 'undeliver') {
          if (
            (routePart.index > currentStateRouteIndex + 1 && routePart.stage !== 'Inbound') ||
            (routePart.index === currentStateRouteIndex + 1 &&
              (!['Inbound', 'At Warehouse'].includes(routePart.stage) ||
                order.current_location_id !== currentStateHandoverLocationId))
          ) {
            canContinue = false;
            failedOrders.push(order);
          }
        }
      }
    }

    this.canContinue = canContinue;
    this.isLastState = isLastState;
    this.errorOrders = failedOrders;

    this.scopeOptions =
      isLastState || !canContinue
        ? []
        : [
            {
              key: '0',
              value: 'current',
              label: 'the handover location into the next state',
            } as ActionOption,
            {
              key: '1',
              value: 'all',
              label: this.state.orderAggregates.length > 1 ? 'its final destination' : 'their final destinations',
            } as ActionOption,
          ];
  }

  /**
   * Looks through the sepcified route and retrieves all the states beyond the specified one.
   * The specified start state will NOT be included in the result.
   * @param inRoute
   * @param startStateId
   */
  getSubsequentStates(inRoute: MapOf<FreightOrderRoute>, startStateId: string): string[] {
    const result: string[] = [];
    if (!inRoute[startStateId]) {
      return result;
    }
    const currentStateIndex = inRoute[startStateId].index;
    for (const [stateId, routePart] of Object.entries(inRoute)) {
      if (routePart.index > currentStateIndex) {
        result.push(stateId);
      }
    }
    return result;
  }

  actionTypeChanged(e: DropdownChangeParams) {
    this.setState({
      selectedScope: e.value,
    });
  }

  async onContinue() {
    const {type} = this.props;
    const {orderAggregates, selectedScope} = this.state;
    if (type === 'deliver') {
      const currentState = localStorage.getItem('current state')!;
      const showRemoveDeliveredTasksDialog = orderAggregates.some(order => {
        return order.runs?.some(run => {
          if (run.state_id !== currentState) {
            return false;
          }
          const runStops = order.stops?.filter(stop => stop.run_id === run.id) ?? [];
          const runStopsIds = runStops.map(stop => stop.id!);
          const runTasks = order.tasks?.filter(task => runStopsIds.includes(task.stop_id!)) ?? [];
          return runTasks.some(task => !task.executed_on);
        });
      });
      if (showRemoveDeliveredTasksDialog) {
        this.setState({showRemoveDeliveredTasksDialog: true});
      } else {
        this.deliverOrders(orderAggregates, selectedScope, currentState, '', false).then(() => this.props.onContinue());
      }
    } else if (type === 'undeliver') {
      this.undeliverOrders().then(() => this.setState({...initialState}, () => this.props.onContinue()));
    }
  }

  /**
   * Triggered by deliver action of the deliver/un-deliver dialog and delivers the state.orders depending on the state.selectedScope
   * in the current state to the handover location or to the final destination. Note: this method also deletes all the tasks
   * of the current and potentially all the next states, depending on the scope.
   */
  async deliverOrders(
    freightOrders: FreightOrder[],
    deliveryScope: DeliverUndeliverActionScope,
    currentState: string,
    reason: string,
    removeTasks: boolean
  ) {
    this.setState({loading: true});

    return Promise.all(
      freightOrders?.map(async (freightOrder: FreightOrder) => {
        const route = {...freightOrder.route};
        let newLocationId = -1;
        const currentStateIndex = freightOrder.route![currentState].index;

        //deliver current state and delete its tasks disregarding the scope (it needs to happen for both current and all)
        const oldStage = freightOrder.route![currentState].stage;
        route[currentState] = {
          ...freightOrder.route![currentState],
          stage: 'Delivered',
        };
        newLocationId =
          this.isLastState && freightOrder.final_destination_id
            ? freightOrder.final_destination_id
            : freightOrder.route![currentState].handover_location_id;

        if (deliveryScope === 'all') {
          newLocationId = freightOrder.final_destination_id ?? newLocationId;
          for (const [stateId, routePart] of Object.entries(freightOrder.route!)) {
            if (routePart.index > currentStateIndex) {
              route[stateId] = {
                ...freightOrder.route![stateId],
                stage: 'Delivered',
              };
            }
          }
        } else if (!this.isLastState) {
          const nextRouteSegment = Object.entries(route).find(entry => entry[1].index === currentStateIndex + 1);
          if (nextRouteSegment) {
            const nextStateId = nextRouteSegment[0];
            route[nextStateId] = {...route[nextStateId], stage: 'At Warehouse'};
          }
        }
        const freightOrderPatch: FreightOrderPatch = {
          route: route,
          current_location_id: newLocationId,
          tles_success: [
            {
              event_type: 'production_stage_transition',
              entity_id: freightOrder.id!,
              entity_type: 'order',
              recorded_at: new Date(),
              recorded_by: getCurrentUserId(),
              content: {
                old_stage: oldStage,
                new_stage: route[currentState].stage,
                message: `Changed in ${currentState}.`,
                reason: reason,
              } as TleContentStageTransition,
            },
          ],
        };

        return this.freightOrdersService
          ?.updateFreightOrder(freightOrder.id ?? '', freightOrderPatch)
          .then(async () => {
            this.twoToast?.showSuccess(`Order ${freightOrder.id} marked as Delivered.`);
            if (removeTasks) {
              const states2deleteTasks = [currentState];
              if (deliveryScope === 'all') {
                states2deleteTasks.push(...this.getSubsequentStates(freightOrder.route!, currentState));
              }
              await this.tasksService
                ?.deleteTasks(freightOrder, states2deleteTasks)
                .then(() => {
                  this.twoToast?.showSuccess(`Tasks for order ${freightOrder.id} removed.`);
                })
                .catch(() => {
                  this.twoToast?.showError(
                    `Sorry, failed to remove tasks for ${freightOrder.id}. Please make sure any un-executed tasks are removed.`
                  );
                });
            }
          })
          .catch(() => {
            this.twoToast?.showError(`Sorry, failed to mark order ${freightOrder.id} delivered.`);
          });
      })
    ).finally(() => {
      this.setState({loading: false});
      MessageService.sendMessage(messages.orderUpdated);
    });
  }

  /**
   * Un-delivers the state.orders based on the state.selectedScope either in the current state only, or all the way to the start of the route.
   * While doing so, all the tasks related to the orders, will be deleted across the current or all the states.
   */
  async undeliverOrders() {
    this.setState({loading: true});
    const freightOrders: FreightOrder[] = this.state.orderAggregates;

    return Promise.all(
      freightOrders.map((freightOrder: FreightOrder) => {
        const currentStateId = localStorage.getItem('current state')!;
        const stage = freightOrder?.location?.type === 'factory' ? 'At Factory' : 'At Warehouse';
        const updateOrder: FreightOrderPatch = {
          route: {
            ...freightOrder.route,
            [currentStateId]: {
              ...freightOrder.route![currentStateId],
              stage,
            },
          },
        };

        return this.freightOrdersService
          ?.updateFreightOrder(freightOrder.id ?? '', updateOrder)
          .then(() => {
            this.twoToast?.showSuccess(`Order ${freightOrder.id} un-delivered successfully.`);
          })
          .catch(error => {
            this.twoToast?.showError(`Un-delivering ${freightOrder.id} failed, please try again.`);
            console.error('error: ' + error);
          });
      })
    ).finally(() => {
      this.setState({loading: false});
      MessageService.sendMessage(messages.orderUpdated);
    });
  }

  locationBodyTemplate(rowData: FreightOrder) {
    const state = localStorage.getItem('current state') ?? '';
    const stage = rowData?.route?.[state]?.stage ?? '';

    if (stage === 'On Board') {
      return <span>{rowData.vehicle?.name}</span>;
    } else {
      if (rowData.location) {
        if (rowData.location.state_id === state) {
          return <span>{rowData.location.name}</span>;
        } else {
          return <span>{`${rowData.location.name} ${rowData.location.state_id}`}</span>;
        }
      }
      return;
    }
  }

  stageBodyTemplate(rowData: FreightOrder) {
    const state = localStorage.getItem('current state')!;
    const stage = rowData?.route?.[state]?.stage ?? '';
    return <span className={`stage-badge stage-${stage.toLowerCase().replaceAll(' ', '-')}`}>{stage}</span>;
  }

  onHide() {
    this.setState({...initialState});
    this.props.onHide();
  }

  onRemoveFromRun(reason: string) {
    const {orderAggregates, selectedScope} = this.state;
    const currentState = localStorage.getItem('current state')!;
    this.deliverOrders(orderAggregates, selectedScope, currentState, reason, true).then(() =>
      this.setState({...initialState}, () => this.props.onContinue())
    );
  }

  onLeaveOnRun(reason: string) {
    const {orderAggregates, selectedScope} = this.state;
    const currentState = localStorage.getItem('current state')!;
    this.deliverOrders(orderAggregates, selectedScope, currentState, reason, false).then(() =>
      this.setState({...initialState}, () => this.props.onContinue())
    );
  }

  render() {
    this.initialise();
    const {showRemoveDeliveredTasksDialog, orderAggregates} = this.state;
    const buttons: JSX.Element[] = [];
    buttons.push(
      <Button
        label="Cancel"
        className={'p-mr-2 p-button-text'}
        onClick={() => {
          this.onHide();
        }}
      />
    );
    if (this.canContinue) {
      buttons.push(
        <Button
          label="Continue"
          className={'p-mr-2'}
          onClick={() => {
            this.onContinue();
          }}
        />
      );
    }
    const footer = <div className={'p-d-flex p-justify-end'}>{buttons}</div>;

    return (
      <TwoDialog
        headerTitle={this.getText('title')}
        showDialog={this.props.showDialog}
        width={60}
        onHide={this.onHide}
        loading={this.state.loading}
        footer={footer}
      >
        <div className="w-100" style={{height: '100%'}}>
          {this.scopeOptions.length > 0 ? (
            <div className="p-grid p-mb-2 p-mx-0">
              <div className="p-col-5 p-as-center">{this.getText('topText')}</div>
              <div className="p-col-7 p-pr-0 p-py-0">
                <Dropdown
                  className="p-d-flex w-100"
                  value={this.state.selectedScope}
                  options={this.scopeOptions}
                  onChange={e => this.actionTypeChanged(e)}
                  optionLabel="label"
                  optionValue="value"
                />
              </div>
            </div>
          ) : (
            <div className="p-mx-2 p-mb-3">{this.getText('topText')}</div>
          )}
          <TwoDataTable
            value={this.canContinue ? this.state.orderAggregates : this.errorOrders}
            activeFilters={{}}
            showPaging={false}
            selectedItems={[]}
          >
            <Column
              header="Dealer"
              field="owner_company.account_number"
              filter={false}
              sortable={false}
              showFilterMenu={false}
              style={{width: '100px'}}
            />
            <Column
              header="Reference"
              field="order.reference"
              filter={false}
              sortable={false}
              showFilterMenu={false}
              style={{width: '150px'}}
            />
            <Column
              header="Code"
              field="id"
              filter={false}
              sortable={false}
              showFilterMenu={false}
              style={{width: '100px'}}
            />
            <Column header="# Boxes" field="size" sortable={false} style={{width: '50px'}} showFilterMenu={false} />
            <Column
              header="Current Location"
              field="location"
              body={this.locationBodyTemplate}
              filter={false}
              sortable={false}
              showFilterMenu={false}
              style={{width: '100px'}}
            />
            <Column
              header="Stage"
              field="stage"
              body={this.stageBodyTemplate}
              filter={false}
              sortable={false}
              showFilterMenu={false}
              style={{width: '50px'}}
            />
          </TwoDataTable>
          <div className="p-mx-2 p-mt-3">{this.getText('note')}</div>
          <RemoveDeliveredTasksDialog
            orderAggregates={orderAggregates}
            showDialog={showRemoveDeliveredTasksDialog ?? false}
            onCancel={() => this.setState({showRemoveDeliveredTasksDialog: false})}
            onRemoveFromRun={this.onRemoveFromRun}
            onLeaveOnRun={this.onLeaveOnRun}
          />
        </div>
      </TwoDialog>
    );
  }
}

export default DeliverUndeliverDialog;
