import React from 'react';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {library} from '@fortawesome/fontawesome-svg-core';
import {
  AppContext,
  TwoAction,
  TwoEntityComponent,
  TwoEntityPanel,
  TwoTimeline,
  TwoTimelineItem,
  MessageService,
  TwoMessage,
  TwoToast,
} from 'two-app-ui';
import './Order.scss';
import OrderDetail from './OrderDetail';
import {faCalendarAlt, faBars, faCamera} from '@fortawesome/pro-regular-svg-icons';
import {
  FreightOrder,
  FreightOrderPatch,
  FreightStage,
  MapOf,
  PickUpFreightOptions,
  QueryParameter,
  StorageFreightOptions,
  TimeLineEvent,
} from 'two-core';
import FreightOrdersService from '../../services/FreightOrdersService';
import TlesService from '../../services/TlesService';
import OrdersService from '../../services/OrdersService';
import TasksService from '../../services/TasksService';
import EditFreightOrderDialog from './EditFreightOrderDialog';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Subscription} from 'rxjs';
import OrderBoxes from './OrderBoxes';
import DeliverUndeliverDialog, {DeliverUndeliverDialogType} from '../Orders/DeliverUndeliverDialog';
import ProofList from '../Proofs/ProofList';
import {getFreightOrderButtonMenuItems} from '../../utils/FreightOrderUtil';
import AssignOrdersToRunDialog from '../Orders/AssignOrdersToRunDialog';
import {FreightOrderRoute} from 'two-core/build/cjs/src/freight-order';
import ChangeLocationDialog from '../Orders/ChangeLocationDialog';

library.add(faCalendarAlt);

interface RouteProps {
  id: string;
}

interface State {
  loading: boolean;
  loadingSecondaryView: boolean;
  showFreightOrderEditDialog: boolean;
  freightOrder: FreightOrder;
  showDeliverDialog: boolean;
  deliveryDialogType: DeliverUndeliverDialogType;
  events: TimeLineEvent[];
  items: TwoTimelineItem[];
  showAssignToRunDialog: boolean;
  showChangeCurrentLocationDialog: boolean;
}

class OrderComponent extends React.Component<RouteComponentProps<RouteProps>, State> {
  static contextType = AppContext;

  freightOrdersService: FreightOrdersService | null = null;
  ordersService: OrdersService | null = null;
  tlesService: TlesService | null = null;
  tasksService: TasksService | null = null;
  twoToast?: TwoToast;

  subscription: Subscription = new Subscription();

  constructor(props: RouteComponentProps<RouteProps>) {
    super(props);

    this.state = {
      loading: false,
      loadingSecondaryView: false,
      showFreightOrderEditDialog: false,
      showAssignToRunDialog: false,
      showChangeCurrentLocationDialog: false,
      freightOrder: {
        state_id: '',
      },
      showDeliverDialog: false,
      deliveryDialogType: 'deliver',
      events: [],
      items: [],
    };

    this.hideFreightOrderEditDialog = this.hideFreightOrderEditDialog.bind(this);
    this.hideAndRefreshFreightOrderEditDialog = this.hideAndRefreshFreightOrderEditDialog.bind(this);
    this.onDeliverUndeliverContinue = this.onDeliverUndeliverContinue.bind(this);
    this.onDeliverUndeliverCancel = this.onDeliverUndeliverCancel.bind(this);
    this.onPickUpByThirdParty = this.onPickUpByThirdParty.bind(this);
    this.onUnloadFreightOrder = this.onUnloadFreightOrder.bind(this);
  }

  componentDidMount() {
    this.tlesService = this.context.tlesService;
    this.freightOrdersService = this.context.freightOrdersService;
    this.tasksService = this.context.tasksService;
    this.twoToast = this.context.twoToast;

    this.subscription = MessageService.getMessage().subscribe(message => {
      const castedMessage = message as TwoMessage;
      if (castedMessage.name && castedMessage.name === 'top-selection-changed') {
        this.props.history.push('/all-orders');
      }
    });

    const id = this.props.match.params.id;
    this.loadOrder(id);
    this.loadEvents(id);
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  loadOrder(orderId: string) {
    this.setState({loading: true});

    const filters: string[] = [];

    filters.push(
      JSON.stringify({
        field: 'id',
        value: orderId,
        condition: '=',
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };

    this.freightOrdersService
      ?.getFreightOrders(params)
      .then(data => {
        const dataRecords = (data?.records as FreightOrder[]) ?? [];
        //fix for array with undefined element
        if (dataRecords[0]) {
          const freightOrder: FreightOrder = {
            ...dataRecords[0],
            shipment_items: dataRecords[0].shipment_items?.filter(item => item) ?? [],
          };

          this.setState({
            freightOrder: freightOrder,
            loading: false,
          });
        }
      })
      .catch(() => {
        this.twoToast?.showError('Sorry, order load failed, please try again.');
        this.setState({loading: false});
      });
  }

  loadEvents(id: string) {
    this.setState({loadingSecondaryView: true});

    const filters: string[] = [
      JSON.stringify({
        field: 'entity_type',
        value: 'order',
      }),
      JSON.stringify({
        field: 'entity_id',
        value: id,
      }),
    ];
    const orderBys = JSON.stringify({field: 'recorded_at', direction: 'DESC'});
    const params: QueryParameter = {
      filters: filters,
      orderBys: [orderBys],
      aggregate: true,
    };
    this.tlesService
      ?.getTimeLineEvents(params)
      .then(data => {
        const events = data.records as TimeLineEvent[];

        const items = events.map(event => {
          const item: TwoTimelineItem = {event: event};
          return item;
        });

        this.setState({
          events: events,
          loadingSecondaryView: false,
          items: items,
        });
      })
      .catch(() => {
        this.twoToast?.showError('Sorry, order events load failed, please try again.');
        this.setState({loadingSecondaryView: false});
      });
  }

  getActions(freightOrder: FreightOrder): TwoAction[] {
    const state = localStorage.getItem('current state')!;

    return getFreightOrderButtonMenuItems(
      freightOrder,
      {
        onEdit: () => this.setState({showFreightOrderEditDialog: true}),
        onRemoveFromRun: () => this.onRemoveFreightOrderFromRun(),
        onAssignToRun: () => this.setState(() => ({showAssignToRunDialog: true})),
        onDeliver: () => this.setState(() => ({showDeliverDialog: true, deliveryDialogType: 'deliver'})),
        onPickUpByThirdParty: () => this.onPickUpByThirdParty(),
        onChangeCurrentLocation: () => this.setState(() => ({showChangeCurrentLocationDialog: true})),
        onUnload: () => this.onUnloadFreightOrder(),
        onUnDeliver: () => this.setState(() => ({showDeliverDialog: true, deliveryDialogType: 'undeliver'})),
      },
      state ?? ''
    );
  }

  async onUnloadFreightOrder() {
    this.setState({loading: true});
    const {freightOrder} = this.state;
    const unloadLocationId = freightOrder?.container?.unload_at_id;

    if (unloadLocationId) {
      let newStage: FreightStage;
      if (
        freightOrder.freight_options?.freight_type === 'Pick Up' &&
        (freightOrder.freight_options as PickUpFreightOptions)?.location_id === unloadLocationId
      ) {
        newStage = 'Ready 4 Pick Up';
      } else if (
        freightOrder.freight_options?.freight_type === 'Storage' &&
        (freightOrder.freight_options as StorageFreightOptions)?.location_id === unloadLocationId
      ) {
        newStage = 'Stored';
      } else {
        newStage =
          Number(unloadLocationId) === Number(freightOrder.final_destination_id) ? 'Delivered' : 'At Warehouse';
      }

      const state = localStorage.getItem('current state')!;
      const routePart = {...freightOrder.route![state], stage: newStage};
      const route = {...freightOrder.route, [state]: routePart};
      const updateOrder: FreightOrderPatch = {
        route: route,
        current_location_id: unloadLocationId,
        at_current_location_since: new Date(),
      };

      return this.freightOrdersService
        ?.updateFreightOrder(freightOrder.id ?? '', updateOrder)
        .then(() => {
          this.twoToast?.showSuccess('Order unloaded successfully.');
          this.setState({loading: false});
          this.loadOrder(freightOrder.id as string);
        })
        .catch(error => {
          this.twoToast?.showError('Sorry, Order unload failed, please try again.');
          console.error('error: ' + error);
          this.setState({loading: false});
        });
    }
    return;
  }

  onPickUpByThirdParty() {
    this.setState({loading: true});
    const {freightOrder} = this.state;
    if (freightOrder.freight_options?.freight_type === '3rd Party') {
      const state = localStorage.getItem('current state')!;
      const routePart = {...freightOrder.route![state], stage: 'With 3rd Party'};
      const route = {...freightOrder.route, [state]: routePart} as MapOf<FreightOrderRoute>;
      const updateOrder: FreightOrderPatch = {
        route: route,
      };
      return this.freightOrdersService
        ?.updateFreightOrder(freightOrder.id ?? '', updateOrder)
        .then(() => {
          this.twoToast?.showSuccess('Order unloaded successfully.');
          this.setState({loading: false});
          this.loadOrder(freightOrder.id as string);
        })
        .catch(error => {
          this.twoToast?.showError('Sorry, Order unload failed, please try again.');
          console.error('error: ' + error);
          this.setState({loading: false});
        });
    }
    return;
  }

  onRemoveFreightOrderFromRun() {
    const {freightOrder} = this.state;
    this.freightOrdersService
      ?.removeFreightOrderTasks(freightOrder)
      .then(() => {
        this.twoToast?.showSuccess('Order removed successfully.');
        this.setState({loading: false});
        this.loadOrder(freightOrder.id as string);
      })
      .catch(error => {
        this.twoToast?.showError('Sorry, Orders remove failed, please try again.');
        console.error('error: ' + error);
        this.setState({loading: false});
      });
  }

  onDeliverUndeliverContinue(): void {
    this.setState({showDeliverDialog: false});
    this.loadOrder(this.state.freightOrder.id!);
  }

  onDeliverUndeliverCancel(): void {
    this.setState({showDeliverDialog: false});
  }

  hideFreightOrderEditDialog() {
    this.setState({showFreightOrderEditDialog: false});
  }

  hideAndRefreshFreightOrderEditDialog() {
    this.hideFreightOrderEditDialog();
    this.loadOrder(this.state.freightOrder.id as string);
    this.loadEvents(this.state.freightOrder.id as string);
  }

  render() {
    const {freightOrder, items} = this.state;

    return freightOrder ? (
      <>
        <TwoEntityComponent title={freightOrder.id ?? ''} actions={this.getActions(freightOrder)}>
          <TwoEntityPanel isPrimary={true}>
            {!this.state.loading ? <OrderDetail freightOrder={freightOrder} /> : <ProgressSpinner />}
          </TwoEntityPanel>

          <TwoEntityPanel label="Bom" icon={faBars} tooltip="Boxes">
            {!this.state.loadingSecondaryView ? (
              <OrderBoxes
                boxes={this.state.freightOrder.shipment_items ? this.state.freightOrder.shipment_items : []}
              />
            ) : (
              <ProgressSpinner />
            )}
          </TwoEntityPanel>
          <TwoEntityPanel label="Proofs" icon={faCamera} tooltip="Proofs">
            {!this.state.loadingSecondaryView ? <ProofList order={freightOrder}></ProofList> : <ProgressSpinner />}
          </TwoEntityPanel>
          <TwoEntityPanel label="Timeline" icon={faCalendarAlt} tooltip="Timeline">
            {!this.state.loadingSecondaryView ? (
              <TwoTimeline key={freightOrder?.id} items={items} />
            ) : (
              <ProgressSpinner />
            )}
          </TwoEntityPanel>
        </TwoEntityComponent>
        <DeliverUndeliverDialog
          showDialog={this.state.showDeliverDialog}
          type={this.state.deliveryDialogType}
          onHide={this.onDeliverUndeliverCancel}
          onContinue={this.onDeliverUndeliverContinue}
          orders={[this.state.freightOrder]}
        />
        <EditFreightOrderDialog
          freightOrderId={freightOrder.id!}
          showDialog={this.state.showFreightOrderEditDialog}
          onHide={this.hideFreightOrderEditDialog}
          onHideAndRefresh={this.hideAndRefreshFreightOrderEditDialog}
        />
        <AssignOrdersToRunDialog
          showDialog={this.state.showAssignToRunDialog}
          onHide={() => this.setState({showAssignToRunDialog: false})}
          freightOrders={[freightOrder]}
        />
        <ChangeLocationDialog
          showDialog={this.state.showChangeCurrentLocationDialog}
          onHide={() => this.setState({showChangeCurrentLocationDialog: false}, () => this.loadOrder(freightOrder.id!))}
          freightOrders={[freightOrder]}
        />
      </>
    ) : (
      <></>
    );
  }
}

export default withRouter(OrderComponent);
