import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse, FreightOrder, QueryParameter, Task, TaskPatch} from 'two-core';

class TasksService extends ApiService {
  endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.tasks ?? '';
  }

  async getTasks(params: QueryParameter): Promise<ApiListResponse> {
    return this.get(this.endpoint, params)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  async updateTask(id: string, data: TaskPatch): Promise<Task> {
    return this.patch(`${this.endpoint}/${id}`, data)
      .then(data => {
        return Promise.resolve(data as Task);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  /**
   * Deletes the tasks of the specified order that are to or took place in one
   * of the specified states and tasks on run not been executed yet.
   * @param forOrder - needs to be the aggregated version of the order, with runs and stops and tasks
   * @param inStates
   */
  async deleteTasks(forOrder: FreightOrder, inStates: string[]): Promise<unknown> {
    if (!forOrder.id) {
      return Promise.reject();
    }
    if (!inStates.length) {
      return Promise.resolve();
    }
    const tasks2delete: Task[] = [];
    for (const run of forOrder.runs ?? []) {
      if (!inStates.includes(run.state_id)) {
        continue;
      }
      const runStops = forOrder.stops?.filter(stop => stop.run_id === run.id) ?? [];
      const runStopsIds = runStops.map(stop => stop.id!);
      const runTasks = forOrder.tasks?.filter(task => runStopsIds.includes(task.stop_id!)) ?? [];
      if (runTasks.some(task => task.executed_on)) {
        continue;
      }
      tasks2delete.push(...runTasks);
    }
    return Promise.all(tasks2delete.map(task => this.delete(`${this.endpoint}/${task.id}`)));
  }

  async updateTaskCompletion(task: Task) {
    const unparsedUser: string = localStorage.getItem('user') ?? '';
    const currentUser = JSON.parse(unparsedUser);
    const userId = currentUser?.uuid ?? '';
    const updatedTask: Task = {
      ...task,
      executed_on: new Date(),
      executed_by_id: userId,
      executed_at: {long: 0, lat: 0},
    };
    return this.updateTask(task?.id?.toString() as string, updatedTask);
  }

  async createTask(data: TaskPatch): Promise<Task> {
    return this.post(`${this.endpoint}`, data)
      .then(data => {
        return Promise.resolve(data as Task);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async deleteTask(id: string): Promise<void> {
    return this.delete(`${this.endpoint}/${id}`)
      .then(() => {
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}

export default TasksService;
