import {FreightStage} from 'two-core';

export interface StageDropdownOption {
  label: FreightStage;
  value: FreightStage;
}
export const stages: StageDropdownOption[] = [
  {label: 'Inbound', value: 'Inbound'},
  {label: 'In Container', value: 'In Container'},
  {label: 'At Factory', value: 'At Factory'},
  {label: 'At Warehouse', value: 'At Warehouse'},
  {label: 'On Board', value: 'On Board'},
  {label: 'With 3rd Party', value: 'With 3rd Party'},
  {label: 'Ready 4 Pick Up', value: 'Ready 4 Pick Up'},
  {label: 'Stored', value: 'Stored'},
  {label: 'Delivered', value: 'Delivered'},
  {label: 'Picked Up', value: 'Picked Up'},
];
